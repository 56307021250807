<!--生产消耗汇总补录-->
<template>
    <div class="fomeStyle_dy invoice-supplement" v-loading="loading">
        <div class="tableContent">
            <table>
                <thead>
                    <tr>
                        <th colspan="24" class="title">
                            <span>任务信息</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th colspan="3" class="required">
                            任务单号:
                        </th>
                        <td colspan="9" @click="chooseTastNum">
                            <el-input
                                placeholder="请选择任务单号"
                                v-model="formData.task_number"
                                readonly
                            >
                                <template slot="suffix">
                                    <i class="iconfont icon009"></i>
                                </template>
                            </el-input>
                        </td>
                        <th colspan="3">
                            客户名称:
                        </th>
                        <td colspan="9">
                            <el-input v-model="taskInfo.customer_name" disabled></el-input>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="3">
                            工程名称:
                        </th>
                        <td colspan="9">
                            <el-input v-model="taskInfo.project_name" disabled></el-input>
                        </td>
                        <th colspan="3">
                            工程地址:
                        </th>
                        <td colspan="9">
                            <el-input v-model="taskInfo.project_address" disabled></el-input>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="3">
                            施工部位:
                        </th>
                        <td colspan="9" class="constructionPosition">
                            <span>{{ taskInfo.place_category_name }} {{ taskInfo.place_name }}</span>
                        </td>
                        <th colspan="3">
                            施工部位描述:
                        </th>
                        <td colspan="9">
                            <el-input type="textarea" v-model="taskInfo.place_detail" disabled></el-input>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="3">
                            <div class="name">
                                供应场站:
                            </div>
                        </th>
                        <td colspan="5">
                            <el-input v-model="taskInfo.station_name" disabled></el-input>
                        </td>
                        <th colspan="3">
                            <div class="name">
                                运输方式:
                            </div>
                        </th>
                        <td colspan="5">
                            <el-input v-model="taskInfo.transport_name" disabled></el-input>
                        </td>
                        <th colspan="3">
                            <div class="name">
                                浇筑方式:
                            </div>
                        </th>
                        <td colspan="5">
                            <el-input v-model="taskInfo.pouring_name" disabled></el-input>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="3">
                            运距（km）:
                        </th>
                        <td colspan="5">
                            <el-input v-model="taskInfo.haul_distance" disabled></el-input>
                        </td>
                        <th colspan="3">
                            任务方量（方）:
                        </th>
                        <td colspan="5">
                            <el-input v-model="taskInfo.quantity" disabled></el-input>
                        </td>
                        <th colspan="3">
                            要求到货时间:
                        </th>
                        <td colspan="5">
                            <el-input v-model="taskInfo.arrival_time" disabled></el-input>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="3">
                            附加型号:
                        </th>
                        <td colspan="5">
                            <el-input v-model="taskInfo.special_require_name" disabled></el-input>
                        </td>
                        <th colspan="3">
                            塌落度:
                        </th>
                        <td colspan="5" class="slump">
                            <div class="exclusiveRow">
                                <el-input v-model="taskInfo.slump" disabled></el-input>
                                <span>±</span>
                                <el-input v-model="taskInfo.slump_name" disabled></el-input>
                            </div>
                        </td>
                        <th colspan="3">
                            泵车类型:
                        </th>
                        <td colspan="5">
                            <el-input v-model="taskInfo.pump_truck_name" disabled></el-input>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="3">
                            工地限高（m）:
                        </th>
                        <td colspan="5">
                            <el-input v-model="taskInfo.limited_height" disabled></el-input>
                        </td>
                        <th colspan="3">
                            工地要料人:
                        </th>
                        <td colspan="5">
                            <el-input v-model="taskInfo.gdylr" disabled></el-input>
                        </td>
                        <th colspan="3">
                            联系电话:
                        </th>
                        <td colspan="5">
                            <el-input v-model="taskInfo.gdylr_phone" disabled></el-input>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="3">
                            工地限方（方）:
                        </th>
                        <td colspan="5">
                            <el-input v-model="taskInfo.limited_volume" disabled></el-input>
                        </td>
                        <th colspan="3">
                            业务员:
                        </th>
                        <td colspan="5">
                            <el-input v-model="taskInfo.employee_name" disabled></el-input>
                        </td>
                        <th colspan="3">
                            业务员电话:
                        </th>
                        <td colspan="5">
                            <el-input v-model="taskInfo.employee_phone" disabled></el-input>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="3">
                            备注:
                        </th>
                        <td colspan="21">
                            <el-input type="textarea" v-model="taskInfo.remarks" disabled></el-input>
                        </td>
                    </tr>
                </tbody>
            </table>
            <el-form :model="formData" ref="form" :rules="rules">
                <table>
                    <thead>
                        <tr>
                            <th colspan="24" class="title">
                                <span>发货明细</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th colspan="3" class="required">
                                发货车号:
                            </th>
                            <td colspan="5">
                                <el-form-item prop="shch">
                                    <el-input
                                        placeholder="—请选择—"
                                        v-model="formData.shch"
                                        @focus="chooseCar"
                                    >
                                        <template slot="suffix">
                                            <i class="iconfont icon009"></i>
                                        </template>
                                    </el-input>
                                </el-form-item>
                            </td>
                            <th colspan="3" class="required">
                                生产机组:
                            </th>
                            <td colspan="5">
                                <el-form-item prop="line_code">
                                    <el-select v-model="formData.line_code" placeholder="—请选择—" @change="chooseProductionLine">
                                        <el-option
                                            v-for="item in productLine"
                                            :key="item.line_code"
                                            :label="item.line_name"
                                            :value="item.line_code"
                                        >
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </td>
                            <th colspan="3">
                                强度等级:
                            </th>
                            <td colspan="5">
                                <el-input v-model="formData.strength_grade_name" placeholder="请输入强度等级" readonly></el-input>
                            </td>
                        </tr>
                        <tr>
                            <th colspan="3">
                                砼方量（方）:
                            </th>
                            <td colspan="5">
                                <el-input type="number" v-model="formData.concrete_volume" placeholder="请输入砼方量"></el-input>
                            </td>
                            <th colspan="3">
                                砂浆方量（方）:
                            </th>
                            <td colspan="5">
                                <el-input type="number" v-model="formData.mortar_volume" placeholder="请输入砂浆方量"></el-input>
                            </td>
                            <th colspan="3">
                                其他:
                            </th>
                            <td colspan="5">
                                <el-input type="number" v-model="formData.virtual_volume" placeholder="请输入"></el-input>
                            </td>
                        </tr>
                        <tr>
                            <th colspan="3">
                                发货方量（方）:
                            </th>
                            <td colspan="5">
                                <el-input type="number" v-model="totalVolume" placeholder="请输入发货方量"></el-input>
                            </td>
                            <th colspan="3">
                                卸料方量（方）:
                            </th>
                            <td colspan="5">
                                <el-input
                                    type="number"
                                    v-model="totalVolume"
                                    placeholder="请输入卸料方量"
                                    readonly
                                ></el-input>
                            </td>
                            <th colspan="3">
                                签收方量（方）:
                            </th>
                            <td colspan="5">
                                <el-input
                                    type="number"
                                    v-model="totalVolume"
                                    placeholder="请输入签收方量"
                                    readonly
                                ></el-input>
                            </td>
                        </tr>
                        <tr>
                            <th colspan="3" class="required">
                                司机:
                            </th>
                            <td colspan="5">
                                <el-input v-model="formData.sjxm" placeholder="请输入司机姓名"></el-input>
                            </td>
                            <th colspan="3" class="required">
                                联系电话:
                            </th>
                            <td colspan="5">
                                <el-form-item prop="sjsjh">
                                    <el-input v-model="formData.sjsjh" placeholder="请输入司机联系电话"></el-input>
                                </el-form-item>
                            </td>
                            <td colspan="8"></td>
                        </tr>
                    </tbody>
                </table>
                <table>
                    <thead>
                        <tr>
                            <th colspan="24" class="title">
                                <span>时间选择</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th colspan="3" class="required">
                                接单时间:
                            </th>
                            <td colspan="5">
                                <el-form-item prop="receive_time">
                                    <el-date-picker
                                        v-model="formData.receive_time"
                                        type="datetime"
                                        placeholder="—请选择—"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </td>
                            <th colspan="3" class="required">
                                开始打料:
                            </th>
                            <td colspan="5">
                                <el-form-item prop="product_time">
                                    <el-date-picker
                                        v-model="formData.product_time"
                                        type="datetime"
                                        placeholder="—请选择—"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </td>
                            <th colspan="3" class="required">
                                完成打料:
                            </th>
                            <td colspan="5">
                                <el-form-item prop="product_complete_time">
                                    <el-date-picker
                                        v-model="formData.product_complete_time"
                                        type="datetime"
                                        placeholder="—请选择—"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </td>
                        </tr>
                        <tr>
                            <th colspan="3" class="required">
                                到达时间:
                            </th>
                            <td colspan="5">
                                <el-form-item prop="arrival_time">
                                    <el-date-picker
                                        v-model="formData.arrival_time"
                                        type="datetime"
                                        placeholder="—请选择—"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </td>
                            <th colspan="3" class="required">
                                开始卸料:
                            </th>
                            <td colspan="5">
                                <el-form-item prop="unload_time">
                                    <el-date-picker
                                        v-model="formData.unload_time"
                                        type="datetime"
                                        placeholder="—请选择—"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </td>
                            <th colspan="3" class="required">
                                结束卸料:
                            </th>
                            <td colspan="5">
                                <el-form-item prop="unload_over_time">
                                    <el-date-picker
                                        v-model="formData.unload_over_time"
                                        type="datetime"
                                        placeholder="—请选择—"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </td>
                        </tr>
                        <tr>
                            <th colspan="3" class="required">
                                进场时间:
                            </th>
                            <td colspan="5">
                                <el-form-item prop="into_time">
                                    <el-date-picker
                                        v-model="formData.into_time"
                                        type="datetime"
                                        placeholder="—请选择—"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </td>
                            <th colspan="3" class="required">
                                出场时间:
                            </th>
                            <td colspan="5">
                                <el-form-item prop="out_time">
                                    <el-date-picker
                                        v-model="formData.out_time"
                                        type="datetime"
                                        placeholder="—请选择—"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </td>
                            <th colspan="3" class="required">
                                调度时间:
                            </th>
                            <td colspan="5">
                                <el-form-item prop="pcsj">
                                    <el-date-picker
                                        v-model="formData.pcsj"
                                        type="datetime"
                                        placeholder="—请选择—"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </el-form>
        </div>
        <div class="btns">
            <el-button
                class="btn-save"
                type="primary"
                :loading="btnSaveLoading"
                @click="saveData(0)"
                v-if="status !==1 "
            >
                保存
            </el-button>
            <el-button
                class="btn-save-push"
                type="primary"
                :loading="btnPushLoading"
                @click="saveData(1)"
                v-if="status !==1 "
            >
                保存并推送
            </el-button>
            <el-button class="btn-close" @click="handleClose">
                关闭
            </el-button>
        </div>
    </div>
</template>
<script>
const invoiceForm = {
    mixstation_code: '', // 场站编码
    mixstation_name: '', // 场站名称
    line_code: '', // 生产线编码
    line_name: '', // 生产线名称
    project_code: '', // 工程编码
    project_name: '', // 工程名称
    task_number: '', // 任务单号
    scbt: '', // 生产拌台
    xdrw: '', // 下达任务
    shch: '', // 发货车号
    plate_number: '', // 车牌号
    vehicle_type_code: '', // 车辆类型编码
    vehicle_type: '', // 车辆类型
    sjxm: '', // 司机姓名
    sjsjh: '', // 司机手机号
    concrete_volume: 0, // 砼方量
    mortar_volume: 0, // 砂浆方量
    volume: 0, // 发货方量
    unload_volume: 0, // 卸料方量
    sign_volume: 0, // 签收方量
    virtual_volume: 0, // 虚方
    haul_distance: 0, // 运距
    strength_grade: '', // 强度等级
    strength_grade_name: '', // 强度等级名称
    special_require: '', // 特殊要求
    special_require_name: '', // 特殊要求名称
    receive_time: '', // 接单时间
    product_time: '', // 开始打料
    product_complete_time: '', // 完成打料
    arrival_time: '', // 到达时间
    unload_time: '', // 开始卸料时间
    unload_over_time: '', // 结束卸料时间
    sign_time: '', // 签收时间
    into_time: '', // 进场时间
    out_time: '', // 出场时间
    pcsj: '', // 调度时间
};
export default {
    name: 'invoice-supplement',
    data() {
        return {
            loading: false,
            carList: [], // 发货车号
            productLine: [], // 生产机组

            pcb_number: '', // 排车单号
            taskInfo: {},
            formData: Object.assign({}, invoiceForm),

            btnSaveLoading: false,
            btnPushLoading: false,
            isEdit: false, // 是否编辑
            status: 0,

            rules: {
                shch: [
                    { required: true, message: '请选择发货车号', trigger: 'blur' },
                ],
                line_code: [
                    { required: true, message: '请选择生产机组', trigger: 'blur' },
                ],
                sjsjh: [
                    { required: true, message: '请输入司机联系电话', trigger: 'blur' },
                ],
                receive_time: [
                    { required: true, message: '请选择接单时间', trigger: 'blur' },
                ],
                product_time: [
                    { required: true, message: '请选择开始打料时间', trigger: 'blur' },
                ],
                product_complete_time: [
                    { required: true, message: '请选择完成打料时间', trigger: 'blur' },
                ],
                arrival_time: [
                    { required: true, message: '请选择到达时间', trigger: 'blur' },
                ],
                unload_time: [
                    { required: true, message: '请选择开始卸料时间', trigger: 'blur' },
                ],
                unload_over_time: [
                    { required: true, message: '请选择结束卸料时间', trigger: 'blur' },
                ],
                into_time: [
                    { required: true, message: '请选择进场时间', trigger: 'blur' },
                ],
                out_time: [
                    { required: true, message: '请选择出场时间', trigger: 'blur' },
                ],
                pcsj: [
                    { required: true, message: '请选择调度时间', trigger: 'blur' },
                ],
            },
        };
    },
    props: {
        extr: {
            type: Object,
        },
    },
    created() {
        this.initData();
    },
    computed: {
        totalVolume() {
            const result = Number(this.formData.concrete_volume) + Number(this.formData.mortar_volume) + Number(this.formData.virtual_volume);
            return result;
        },
    },
    methods: {
        async initData() {
            if (this.extr && this.extr.row) {
                this.isEdit = true;
                this.pcb_number = this.extr.row.pcb_number;
                this.formData.task_number = this.extr.row.task_number;
                await this.getPcdRecord();
                this.getCarList();
                this.getFormulaline();
            }
        },
        // 选择任务单号
        chooseTastNum() {
            if (this.isEdit) {
                return;
            }
            this.$toast(
                {
                    title: true,
                    text: '选择任务单号',
                    type: 'eject',
                    width: '15rem',
                    height: '90%',
                    t_url: 'tableList/index',
                    extr: {
                        code: {
                            TableCode: 'fhdbl_xzrw',
                        },
                        sureCallback: this.chooseTastNumCallback,
                    },
                }
            );
        },
        async chooseTastNumCallback(data) {
            this.formData = Object.assign({}, invoiceForm);
            this.formData.task_number = data.task_number;
            await this.getTaskInfo();
            this.getCarList();
            this.getFormulaline();
        },
        // 选择发货车号
        async chooseCar() {
            if (!this.formData.task_number) {
                this.$message.warning('请先选择任务信息');
                return;
            }
            const carList = await this.getStationCarList();
            this.$toast({
                title: true,
                text: '选择车辆',
                type: 'eject',
                width: '14rem',
                height: '8rem',
                t_url: 'productionManagement/productionDelivery/pullWaterForm/selectVehicle',
                extr: {
                    wait_car_list: carList,
                    WaitCarActive: this.formData.shch,
                    sureCallback: this.chooseCarCallback,
                },
            });
        },

        // 选择生产线路
        chooseProductionLine(linecode) {
            for (const line of this.productLine) {
                if (line.line_code === linecode) {
                    this.formData.line_name = line.line_name;
                    this.formData.scbt = line.scbt;
                    break;
                }
            }
        },

        // 选择车辆回调
        chooseCarCallback(data) {

            this.formData.plate_number = data.plate_number;
            this.formData.shch = data.plate_small_number;
            this.formData.vehicle_type = data.vehicle_type;
            this.formData.vehicle_type_code = data.vehicle_type_code;
            this.formData.sjxm = data.driver_name;
            this.formData.sjsjh = data.driver_phone;

            this.formData.concrete_volume = data.bulk_density;
        },

        // 保存数据 // 0 保存 1 保存并推送
        saveData(op) {
            this.formData.volume = this.totalVolume || 0;
            this.formData.unload_volume = this.totalVolume || 0;
            this.formData.sign_volume = this.totalVolume || 0;

            this.formData.concrete_volume = this.formData.concrete_volume || 0;
            this.formData.mortar_volume = this.formData.mortar_volume || 0;
            this.formData.virtual_volume = this.formData.virtual_volume || 0;

            this.$refs.form.validate((valid, invalidFields) => {
                if (!valid) {
                    this.$message.warning(Object.values(invalidFields)[0][0].message);
                    return false;
                }
                if (op === 0) {
                    this.btnSaveLoading = true;
                } else {
                    this.btnPushLoading = true;
                }
                if (this.isEdit) {
                    this.$axios.post('/interfaceApi/production/productpcbrerecord/modify/' + op, this.formData)
                        .then(data => {
                            if (data) {
                                this.$parent.hide();
                                this.$parent.hackReset();
                                // this.updateTable();
                                if (data.Description) {
                                    this.$confirm(data.Description, '提示', {
                                        showCancelButton: false,
                                        confirmButtonText: '确定',
                                        type: 'warning',
                                    }).then(() => {});
                                }
                            }
                        }).catch(error => {
                            this.$message.error(error.ErrorCode.Message);
                        }).finally(() => {
                            this.btnSaveLoading = false;
                            this.btnPushLoading = false;
                        });
                } else {
                    this.$axios.post('/interfaceApi/production/productpcbrerecord/add/' + op, this.formData)
                        .then(data => {
                            if (data) {
                                this.$parent.hide();
                                this.$parent.hackReset();
                                if (data.Description) {
                                    this.$confirm(data.Description, '提示', {
                                        showCancelButton: false,
                                        confirmButtonText: '确定',
                                        type: 'warning',
                                    }).then(() => {});
                                }
                            }
                        }).catch(error => {
                            this.$message.error(error.ErrorCode.Message);
                        }).finally(() => {
                            this.btnSaveLoading = false;
                            this.btnPushLoading = false;
                        });
                }
            });
        },
        // 刷新列表
        updateTable() {
            window.updateTable();
        },
        // 获取发货车辆列表
        getStationCarList() {
            return this.$axios.get('/interfaceApi/production/vehicleinfo/vehiclelist/' + this.formData.mixstation_code);
        },
        // 获取任务信息
        async getTaskInfo() {
            const data = await this.$axios.get('/interfaceApi/production/producttask/get_task_info/' + this.formData.task_number);
            if (data) {
                this.taskInfo = data;
                this.formData.project_code = data.project_code;
                this.formData.project_name = data.project_name;
                this.formData.mixstation_code = data.station;
                this.formData.mixstation_name = data.station_name;
                this.formData.special_require = data.special_require;
                this.formData.special_require_name = data.special_require_name;
                this.formData.strength_grade = data.strength_grade;
                this.formData.strength_grade_name = data.strength_grade_name;
            }
        },
        // 修改的时候 获取记录
        async getPcdRecord() {
            try {
                this.loading = true;
                const data = await this.$axios.get('/interfaceApi/production/productpcbrerecord/rerecord/' + this.pcb_number);
                if (data) {
                    this.taskInfo = data.task;
                    this.formData = data.rerecord;
                    this.status = data.status;
                }
                this.loading = false;
            } catch (error) {
                this.$message.error(error.ErrorCode.Message);
            }

        },
        // 获取发货车号
        getCarList() {
            this.$axios.get('/interfaceApi/production/vehicleinfo/vehiclelist/' + this.formData.mixstation_code).then(data => {
                this.carList = data || [];
            }).catch(error => {
                this.$message.error(error.ErrorCode.Message);
            });
        },
        // 获取生产机组
        getFormulaline() {
            if (!this.formData.task_number) {
                this.$message.warning('请先选择任务信息');
                return;
            }
            this.$axios.get('/interfaceApi/production/productformula/formulaline/' + this.formData.task_number).then(data => {
                this.productLine = data || [];
            }).catch(error => {
                this.$message.error(error.ErrorCode.Message);
            });
        },
        handleClose() {
            this.$parent.hide();
        },
        // 数据简单校验
        valideData() {
            if (!this.formData.task_number) {
                this.$message.warning('请选择任务单号');
                return false;
            }
            if (!this.formData.shch) {
                this.$message.warning('请选择发货车号');
                return false;
            }
            if (!this.formData.sjxm) {
                this.$message.warning('请输入司机姓名');
                return false;
            }
            if (!this.formData.sjsjh) {
                this.$message.warning('请输入司机联系电话');
                return false;
            }
            return true;
        },
    },
};

</script>

<style lang="stylus" scoped>
.invoice-supplement
    padding 0
    height 100%
    .tableContent
        padding 0 0.14rem
        height calc(100% - 0.74rem);
        overflow auto;
        table
            td
                .el-form-item
                    vertical-align: top;
    .btns
        display flex
        justify-content center;
        align-items center;
        margin 0.14rem;
        .el-button,/deep/ .el-button
            width: 2.6rem;
            height: 0.46rem;
            margin 0 0.25rem;
            font-size 0.16rem
        .btn-save,.btn-save-push
            background: #1577D2;
        .btn-close
            border: 1px solid #1577D2;
            color #1577D2;
</style>
